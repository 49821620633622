import React from 'react';
import * as PropTypes from 'prop-types';
import { get_ } from 'sf-modules';
import Link from '@/yema-designkit/webkit/atoms/Link';
import Section from '@/yema-designkit/webkit/layouts/Section';
import CMS_GridCards from './CMS_GridCards';

const CMS_RowCardGroup_Comp = ({ content, id = '' }) => {
    if (!content || content.length <= 0) return '';

    const element = !Array.isArray(content) ? [content] : content;

    return element.map((cardGroup, index) => {
        const title = cardGroup.title;
        const background = cardGroup.background_color;

        return (
            <Section
                key={`${cardGroup.title}+${cardGroup.fragment_id}+${index}`}
                id={get_(content, ['fragment_id'], '')}
                style={{
                    backgroundColor: background
                        ? background
                        : background === 'no'
                        ? 'transparent'
                        : '',
                }}
            >
                {/*ROW TITLE & LINK*/}
                {title && (
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='mb-6 w-full px-2'>
                                <div className='flex flex-col items-center justify-between text-center sm:flex-row'>
                                    <h2 className='font-heading text-h2 font-heading-regular'>
                                        {title}
                                    </h2>

                                    {cardGroup.button && cardGroup.button.link && (
                                        <Link
                                            underline={true}
                                            label={cardGroup.button.text}
                                            href={cardGroup.button.link}
                                        >
                                            {cardGroup.button.text}
                                        </Link>
                                    )}
                                </div>

                                {cardGroup.content ? (
                                    <div
                                        className='text-center font-body text-normal font-body-regular sm:text-left'
                                        dangerouslySetInnerHTML={{
                                            __html: cardGroup.content,
                                        }}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>
                    </div>
                )}

                <CMS_GridCards
                    content={cardGroup.cards}
                    nbColsD={cardGroup.columns_desktop ? cardGroup.columns_desktop : 1}
                    nbColsM={cardGroup.columns_mobile ? cardGroup.columns_mobile : 1}
                />
            </Section>
        );
    });
};

CMS_RowCardGroup_Comp.defaultProps = {};

CMS_RowCardGroup_Comp.propTypes = {
    content: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
};

const CMS_RowCardGroup = React.memo(CMS_RowCardGroup_Comp);
export default CMS_RowCardGroup;
