import React from 'react';
import dynamic from 'next/dynamic';
import cx from 'classnames';
import * as PropTypes from 'prop-types';

const CMS_CardProduct = dynamic(() => import('./CMS_CardProduct'));
const CMS_CardImageText = dynamic(() => import('./CMS_CardImageText'));
const CMS_CardSeeMore = dynamic(() => import('./CMS_CardSeeMore'));

const components = {
    CARD_IMAGE_TOP: CMS_CardImageText,
    CARD_IMAGE_LEFT: CMS_CardImageText,
    CARD_IMAGE_RIGHT: CMS_CardImageText,
    CARD_IMAGE_FORM_BOTTOM_LEFT: CMS_CardImageText,
    CARD_PRODUCT: CMS_CardProduct,
    CARD_SEE_MORE: CMS_CardSeeMore,
    image_top: CMS_CardImageText,
    image_side: CMS_CardImageText,
    description_form: CMS_CardImageText,
    blog: CMS_CardImageText,
    product: CMS_CardProduct,
};

const RenderRow = ({ data, nbColsD, nbColsM }) => {
    return (
        <div className='row'>
            {data.map((item, idx) => {
                let layout = item.layout ? item.layout : {};
                const isSeeMoreCard =
                    idx === data.length - 1 &&
                    (layout === 'CARD_IMAGE_TOP' || layout === 'image_top') &&
                    item.button &&
                    item.button.link &&
                    item.content &&
                    item.content.includes('Ver todo');
                const CustomComponent = layout
                    ? isSeeMoreCard
                        ? components['CARD_SEE_MORE']
                        : components[layout]
                    : {};

                if (!CustomComponent) return '';
                else {
                    const numColumnsD =
                        parseInt(nbColsD) === 1
                            ? 'lg:w-full'
                            : nbColsD >= 4
                            ? 'lg:w-1/4'
                            : 'lg:w-1/' + nbColsD;

                    const numColumnsM =
                        parseInt(nbColsM) === 1
                            ? 'sm:w-full'
                            : nbColsM >= 4
                            ? 'sm:w-1/4'
                            : 'sm:w-1/' + nbColsM;

                    return (
                        <div
                            key={idx}
                            id={item?.product ? `product-card-${item.product.sku}` : null}
                            className={cx(
                                'mb-4 px-5 sm:mb-6 sm:px-2 md:px-2',
                                `w-full ${numColumnsM} ${numColumnsD}`
                            )}
                        >
                            <CustomComponent content={item} />
                        </div>
                    );
                }
            })}
        </div>
    );
};

const CMS_GridCards_Comp = ({ content, nbColsD, nbColsM }) => {
    let cards = Object.values(content);
    if (!cards || cards.length <= 0) return '';

    return (
        <div className='container-fluid m-0 mx-auto sm:max-w-screen-md md:max-w-none'>
            <RenderRow data={content} nbColsD={nbColsD} nbColsM={nbColsM} />
        </div>
    );
};

CMS_GridCards_Comp.defaultProps = {};

CMS_GridCards_Comp.propTypes = {
    content: PropTypes.array.isRequired,
    nbColsD: PropTypes.string,
    nbColsM: PropTypes.string,
};

const CMS_GridCards = React.memo(CMS_GridCards_Comp);
export default CMS_GridCards;
